<template>
        <svg viewbox="0 0 320 170" xmlns="http://www.w3.org/2000/svg" width="320" height="170">
            <image xlink:href="../assets/画像图-底部.png" x="22.5" y="110" width="275" height="70"></image>
            <rect fill="#ebebeb" filter="url(#gaussian_blur)" 
             x="77.5" y="12" rx="20" ry="15" width="165" height="116"></rect>
            <rect :fill="'url(#' + info.bgColor + '_linear)'" rx="20" ry="15" x="82.5" y="12" width="155" height="116"></rect>
            <path d="M 172.5 128 L238 76 L238 113 A20 15 0 0 1 217.5 128 L238 128z" style="fill: #ebebeb;" v-if="info.scoreCut.auto_rate"></path>
            <path d="M 147.5 128 L82 76 L82 113 A20 15 0 0 0 102.5 128 L82 128z" style="fill: #ebebeb;" v-if="info.scoreCut.complance_rate"></path>
            <path d="M 172.5 12 L238 64 L238 27 A20 15 0 0 0 217.5 12 L238 12z" style="fill: #ebebeb;" v-if="info.scoreCut.diesel_use_rate"></path>
            <path d="M 147.5 12 L82 64 L82 27 A20 15 0 0 1 102.5 12 L82 12z" style="fill: #ebebeb;" v-if="info.scoreCut.online_rate"></path>
            <image xlink:href="../assets/画像图-椭圆形.png" x="112" y="22" width="96" height="96"></image>
            <text x="136" y="68" fill="#fff" style="font-size: 14px;font-weight: bold;">{{info.calcNum.quantity_rate | formatDecimal(2, '%')}}</text>
            <text x="138" y="85" fill="#FFFFFF99" style="font-size: 12px;">质量得分</text>
            <g>
                <text x="18" y="28" fill="#0E9F6E" style="font-size: 14px;font-weight: bold;">{{info.calcNum.online_rate | formatDecimal(2, '%')}}</text>
                <text x="13" y="45" fill="#8C8C8C" style="font-size: 12px;">调试在线率</text>
                
                <text x="252" y="28" fill="#0E9F6E" style="font-size: 14px;font-weight: bold;">{{info.calcNum.diesel_use_rate | formatDecimal(2, '%')}}</text>
                <text x="247" y="45" fill="#8C8C8C" style="font-size: 12px;">能耗利用率</text>
                
                <text x="18" y="95" fill="#0E9F6E" style="font-size: 14px;font-weight: bold;">{{info.calcNum.complance_rate | formatDecimal(2, '%')}}</text>
                <text x="13" y="112" fill="#8C8C8C" style="font-size: 12px;">工艺合规率</text>
                
                <text x="252" y="95" fill="#0E9F6E" style="font-size: 14px;font-weight: bold;">{{info.calcNum.auto_rate | formatDecimal(2, '%')}}</text>
                <text x="247" y="112" fill="#8C8C8C" style="font-size: 12px;">调试自动化率</text>
            </g>            
        </svg>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: ()=>{}
        }
    }
}
</script>